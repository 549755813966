import {Button} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';

import SwipeableViews from 'react-swipeable-views';
import {virtualize} from 'react-swipeable-views-utils';
import {mod} from 'react-swipeable-views-core';

import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import ArrowRightSmall from '../../images/ArrowRightSmall.svg'
import {AnchorLink} from "gatsby-plugin-anchor-links";

const SliderContainer = styled.div`
  position: relative;
  top: ${props => props.height < 437 ? 30 : 50}px;
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  min-height: ${props => 750}px;
  background: rgb(11, 10, 10);
  @media (min-width: ${props => props.maxWidth}px) {
    margin: 0 auto;
  }

  @media (max-width: 700px) {
    min-height: 550px;
  }
  @media (max-width: 560px) {
    height: 290px;
  }
  @media (max-width: 380px) {
    min-height: 550px;
  }
`

const SliderItems = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  background: rgb(11, 10, 10);
  align-items: center;
  position: relative;
`

const HideArrowDiv = styled.div`
  zIndex: 5;
  position: absolute;
  left: ${props => props.direction === "left" ? -15 + "px" : "none"};
  right: ${props => props.direction === "right" ? -15 + "px" : "none"};
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.25);
  @media (max-width: 991px) {
    display: none;
  }
`

const TestDiv = styled.div`
  position: absolute;
  left: 125px;
  width: 72%;
  display: flex;
  align-items: center;
  z-index: 100;
  @media (max-width: 1560px) {
    width: 70%;
  }
  @media (max-width: 1530px) {
    width: 69%;
  }
  @media (max-width: 1450px) {
    width: 67%;
  }
  @media (max-width: 1400px) {
    width: 65.5%;
  }
  @media (max-width: 1350px) {
    width: 63.8%;
  }
  @media (max-width: 1300px) {
    width: 73%;
  }
  @media (max-width: 1270px) {
    width: 72%;
  }
  @media (max-width: 1215px) {
    width: 70%;
  }
  @media (max-width: 1170px) {
    width: 68.5%;
  }
  @media (max-width: 1120px) {
    width: 67.5%;
  }
  @media (max-width: 1100px) {
    width: 68%;
    left: 112px;
  }
  @media (max-width: 1060px) {
    width: 66.5%;
  }
  @media (max-width: 1020px) {
    width: 64.5%;
  }
`

const InfoCard = styled.div`
  width: 400px;
  min-width: 280px;
  min-height: 447px;
  position: absolute;
  transition: left 0.5s;
  left: ${props => props.active ? -65 : 0}px;
  background: #1C1A1A;
  color: white;
  border-radius: 16px;
  z-index: 100;
  padding: 84px 70px;
  @media (max-width: 1450px) {
    width: 315px;
    padding: 30px 20px 0px 60px;
    min-height: 340px;
  }
  @media (max-width: 1300px) {
    width: 320px;
    min-height: 340px;
  }
  @media (max-width: 1200px) {
    width: 270px;
    min-height: 310px;
  }
  @media (max-width: 1100px) {
    width: 256px;
    min-width: 240px;
    padding: 28px 10px 28px 46px;
    min-height: 300px;
  }
  @media (max-width: 991px) {
    min-height: auto;
    width: 255px;
    min-width: 260px;
    position: absolute;
    z-index: 100;
    left: 55px;
    top: 75px;
    background: #1C1A1A;
    color: white;
    border-radius: 16px;
    padding: 24px 18px;
  }
  @media (max-width: 600px) {
    left: 45px;
    top: 90px;
  }
  @media (max-width: 380px) {
    left: 25px;
    top: 65px;
    min-width: auto;
    width: 78%;
  }
`

const InfoCardTitle = styled.div`
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 10px;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
  @media (max-width: 380px) {
    font-size: 16px;
  }
`

const InfoCardLine = styled.div`
  font-size: 18px;
  margin: 36px 0;
  font-weight: 200;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 380px) {
    font-size: 14px;
  }
`

const CardLink = styled(AnchorLink)`
  text-decoration: none;
`


const InfoCardLink = styled(AnchorLink)`
  display: flex;
  font-size: 16px;
  width: fit-content;
  border-color: #1C1A1A;
  transition: 0.1s border-color;
  color: white;
  text-decoration: none;
  @media (max-width: 1200px) {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #4285F4;
    border-radius: 2px;
    text-decoration: none !important;
  }
`

const SliderNavLeft = styled.div`
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 8px;
  z-index: 3;

  :hover {
    background: white;
    border-color: white;
  }
`

const SliderNavRight = styled.div`
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 8px;
  z-index: 3;

  :hover {
    background: white;
    border-color: white;
  }
`

const Item = styled.div`
  position: relative;
  padding-top: 300px;
  display: flex !important;
  justify-content: center;
  align-items: center;
`

const ItemImg = styled.div`
  width: 90% !important;
  display: flex;
  z-index: -1;
  align-items: center;


  & img {
    width: 100% !important;
    transition: opacity 0.5s;
    opacity: ${props => props.active ? 1 : 0.4};
    z-index: -1;
    object-fit: cover;
    height: 455px;
    margin: 0 auto;
    max-height: 550px;
    border-radius: 10px;
    @media (max-width: 700px) {
      height: 310px;
    }
    @media (max-width: 560px) {
      height: 290px;
    }
    @media (max-width: 380px) {
      height: 220px;
    }
  }
`

const Item2 = styled.div`
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 900px;
`

const ItemImg2 = styled.div`
  width: 90% !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 220px;
  transition: transform 0.5s, opacity 0.5s;
  opacity: ${props => props.active ? 1 : 0.4};
  transform: scale(${props => props.active ? 1.2 : 1});
  @media (max-width: 1300px) {
    margin: 0 100px;
    justify-content: flex-start;
  }

  & img {
    max-height: 620px;
    height: 600px;
    object-fit: cover;
    max-width: 860px;
    width: 100% !important;
    margin: 0 auto;
    border-radius: 10px;
    @media (max-width: 1600px) {
      height: 520px;
    }
    @media (max-width: 1500px) {
      height: 440px;
    }
    @media (max-width: 1400px) {
      height: 410px;
    }
    @media (max-width: 1300px) {
      height: 500px;
    }
    @media (max-width: 1200px) {
      height: 420px;
    }
    @media (max-width: 1100px) {
      height: 380px;
    }
  }
`

const ImgLink = styled(AnchorLink)`
  width: 100% !important;
`

const NavButtonStyles = {
    width: "68px",
    height: "68px",
    paddingTop: "13px",
    borderRadius: "8px",
    background: "inherit",
    borderColor: "inherit",
    zIndex: 5
}

const VirtualizeSwipeableViews = virtualize(SwipeableViews);


function slideRenderer(params) {
    const {index, key, items, selectedItem} = params;
    let item = items[mod(index, items.length)];
    return (
        <Item key={key}>
            <CardLink to={'/projects/' + item.url}>
                <InfoCard>
                    <InfoCardTitle><br></br>{item.title}</InfoCardTitle>
                    <InfoCardLine style={{color: "#FFFFFF"}}>{item.description}</InfoCardLine>
                    <InfoCardLink to={'/projects/' + item.url}>
                        ПОДРОБНЕЕ
                        <img src={ArrowRightSmall} style={{marginLeft: "14px"}} alt="More"/>
                    </InfoCardLink>
                </InfoCard>

                <ItemImg active={index === selectedItem}>
                    <img style={{zIndex: -1}} className="locked" src={item.img} alt={'pic-carousel'}/>

                </ItemImg>
            </CardLink>
        </Item>
    )

}

function slideRenderer2(params) {
    const {index, key, items, selectedItem} = params;
    let item = items[mod(index, items.length)];
    return (
        <Item2 key={key}>
            <InfoCard active={index === selectedItem} to={'/projects/' + item.url}>
                <InfoCardTitle>{item.title}</InfoCardTitle>
                <InfoCardLine style={{color: "#FFFFFF"}}>{item.description}</InfoCardLine>
                <InfoCardLink to={'/projects/' + item.url}>
                    ПОДРОБНЕЕ
                    <img src={ArrowRightSmall} style={{marginLeft: "14px"}} alt="More"/>
                </InfoCardLink>
            </InfoCard>
            <ItemImg2 active={index === selectedItem}>
                <ImgLink to={'/projects/' + item.url}>
                    <img className="locked" src={item.img} alt={"photo" + item.url}/>
                </ImgLink>
            </ItemImg2>

        </Item2>
    )

}

const springConfig = {
    duration: "0.8s",
    easeFunction: "cubic-bezier(0.1, 0.35, 0.2, 1)",
    delay: "0.05s",
};

const CarouselProjects = ({items}) => {
    const screens = useBreakpoint();

    const [selectedItem, setSelectedItem] = useState(0);
    const [hoverArrow, setHoverArrow] = useState(null);

    const handleMouseEnter = direction => {
        setHoverArrow(direction);
    }

    const handleMouseLeave = () => setHoverArrow(null);

    return (
        <SliderContainer>
            {
                !screens.lg ?

                    <VirtualizeSwipeableViews springConfig={springConfig} onChangeIndex={setSelectedItem}
                                              index={selectedItem} style={{padding: screens.xs ? "0 30px" : "0 80px"}}
                                              slideRenderer={(e) => slideRenderer({...e, items, selectedItem})}/>

                    :
                    <SliderItems>

                        <VirtualizeSwipeableViews slideStyle={{overflow: "initial"}} springConfig={springConfig}
                                                  onChangeIndex={setSelectedItem} index={selectedItem}
                                                  style={{padding: screens.xs ? "0 30px" : "0 190px"}}
                                                  slideRenderer={(e) => slideRenderer2({...e, items, selectedItem})}/>

                        <TestDiv id="test">
                            <HideArrowDiv direction="left">
                                <SliderNavLeft onClick={() => setSelectedItem(selectedItem - 1)}>
                                    <Button type="primary" onMouseEnter={() => handleMouseEnter("left")}
                                            onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                        <LeftArrow color={hoverArrow === "left" && "#4285F4"}/>
                                    </Button>
                                </SliderNavLeft>
                            </HideArrowDiv>


                            <HideArrowDiv direction="right">
                                <SliderNavRight onClick={() => setSelectedItem(selectedItem + 1)}>
                                    <Button type="primary" onMouseEnter={() => handleMouseEnter("right")}
                                            onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                        <RightArrow color={hoverArrow === "right" && "#4285F4"}/>
                                    </Button>
                                </SliderNavRight>
                            </HideArrowDiv>
                        </TestDiv>
                    </SliderItems>
            }
        </SliderContainer>
    )
}

export default CarouselProjects;
