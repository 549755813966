import React from "react";
import Img1 from "../../assets/Vector.svg";
import Img2 from "../../assets/ruckus.svg";
import Img3 from "../../assets/came.svg";
import Img4 from "../../assets/Frame.svg";
import Img5 from "../../assets/Group.svg";
import Img6 from "../../assets/Group2.svg";
import Img7 from "../../assets/BPT.svg";
import Img8 from "../../assets/Hikvision.svg";
import Img9 from "../../assets/logo1.svg";
import Img10 from "../../assets/path23.svg";
import Img11 from "../../assets/Vector2svg.svg";
import Img12 from "../../assets/iRidium.svg";
import "../layout.css";
import styled from "styled-components";
import PlusComponent from "../../ui/plusComponent";

const DATA = [
  {
    img: <Img1 />,
  },
  {
    img: <Img2 />,
  },
  {
    img: <Img3 />,
  },
  {
    img: <Img4 />,
  },
  {
    img: <Img5 />,
  },
  {
    img: <Img6 />,
  },
  {
    img: <Img7 />,
  },
  {
    img: <Img8 />,
  },
  {
    img: <Img9 />,
  },
  {
    img: <Img10 />,
  },
  {
    img: <Img11 />,
  },
  {
    img: <Img12 />,
  },
];

const ContainerItem = styled.div`
  height: 195px;
  border: 1px solid #1c1a1a;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 368px) {
    max-width: 150px;
    height: 120px;
  }
  @media (max-width: 326px) {
    max-width: 120px;
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 110px;
  @media (max-width: 1268px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 968px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
    display: none;
    max-width: 100%;
  }
`;

const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 110px;
    margin: 10px;
    max-width: 100%;
  }
  @media (max-width: 568px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 110px;
    margin: 10px;
  }
`;

const Div = styled.div`
  margin-top: 110px;
  margin-bottom: 150px;
  @media (max-width: 968px) {
    margin-bottom: 110px;
  }
`;

const Partners = () => {
  return (
    <Div>
      <PlusComponent text="Партнеры" />
      <Container>
        {DATA.map((item, index) => {
          return (
            <ContainerItem key={index}>
              {item.img}
            </ContainerItem>
          );
        })}
      </Container>
      <ContainerMobile>
        {DATA.map((item, index) => {
          return <ContainerItem key={index}>{item.img}</ContainerItem>;
        })}
      </ContainerMobile>
    </Div>
  );
};

export default Partners;
